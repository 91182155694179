import React, {useEffect} from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../helpers/axios";
import Info from "../components/modals/Info";

function WishCard() {
    const navigate = useNavigate();

    const onRecievedMessage = (e) => {
        if(e.data){
            switch(e.data){
                case 'goto_back':
                    navigate("/");
                    break;
                case 'participate':
                    if(document.getElementById('rec-trigger-show')){
                        document.getElementById('rec-trigger-show').click();
                    }
                    break
                default:
                    if((typeof e.data === 'string' || e.data instanceof String) && e.data.includes('{"type":"save"')){
                        let dataArr = JSON.parse(e.data);
                        if(dataArr && dataArr.type) {
                            if(dataArr.type === 'save') {
                                saveCard(dataArr.arr);
                            }
                        }
                    }
                    break
            }
        }
    }

    useEffect(()=>{
        window.addEventListener("message", onRecievedMessage);

        return function () {
            window.removeEventListener("message", onRecievedMessage);
        };
    });

    const saveCard = async(type) => {
        const formData = new FormData();
        formData.append("type", type);
        try {
            const response = await axiosInstance.post('saveCard', formData);
        } catch (error) {

        }
    }

    return(
        <div className="content">
            <div className="sc__wishcard">
                <div className="wishcard__box">
                    <iframe src={process.env.PUBLIC_URL + 'game/index.html'}></iframe>
                </div>
            </div>
        </div>
    )
}

export default WishCard;