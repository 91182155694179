import axios from 'axios'

const baseUrl = process.env.REACT_APP_API_PROXY;

const axiosInstance = axios.create({
  baseURL: baseUrl,
  headers: {
    'Content-Type': 'multipart/form-data',
    'Authorization': 'Basic dGVzdGVyOjVFM2cyM1loQlJ0dThPZmE='
  },
});

axiosInstance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('auth_key');
        if (token) {
            config.headers['X-Auth-Token'] = token;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;

        if (error.response.status === 400){
            const token = localStorage.getItem('auth_key');
            if(token){
                //deleteCookie('pd_token');
                //deleteCookie('pd_refresh');
                //window.location.reload();
            }
        }

        if (error.response.status === 401){
            localStorage.removeItem('auth_key');
            window.location.reload();
        }

        return Promise.reject(error);
    }
);

export default axiosInstance