import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react'

export const Modal = ({
    isShown,
    modalContent,
    hide,
    className,
  }) => {
    return(
        <Transition appear show={isShown} as={Fragment}>
            <Dialog as="div" className={'modal ' + className} onClose={()=>{hide()}}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div id="modal__backdrop" className="modal__backdrop" />
                </Transition.Child>

                <div id="modal__content" className="modal__content">
                    <div className="modal__content-area">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-700"
                            enterFrom="opacity-0 scale-75"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-150"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-75"
                        >
                            <Dialog.Panel className="modal__content-box">
                                <div className="modal__content-box-bl">
                                    <div
                                        id="modal-close"
                                        className="modal__close"
                                        onClick={() => {
                                            hide();
                                        }}
                                    >
                                        <svg className="desk-only" width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.9495 13.4605L2 1.51102" stroke="#E9BB6F" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M14.0003 1.49999L2.00879 13.4915" stroke="#E9BB6F" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                        <svg className="mb-only" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M15.9509 15.9632L2 2.01221" stroke="#FFF7F4" strokeWidth="2.1028" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M16.0103 2L2.01025 16" stroke="#FFF7F4" strokeWidth="2.1028" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                    </div>

                                    {modalContent}

                                    <div className="modal__star mb-only"/>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
};
