import {useState} from 'react'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import {useEnd, useModal} from "../../context";
import Reg from "./Reg";
import Pass from "./Pass";
import useEffectOnce from "../../hooks/useEffectOnce";
import axiosInstance from "../../helpers/axios";

function Login(){
    const [isSubmitting, setIsSubmitting] = useState(false);
    const {end} = useEnd();
    const { show, hide} = useModal();

    const schema = yup.object({
        login: yup.string().email('Некорректный E-mail').required('Некорректный E-mail'),
        password: yup.string().min(6, '6 символов').required('Некорректный пароль'),
        remember: yup.boolean().oneOf([false, true], 'Необходимо согласие'),
    }).required();

    const { register, setValue, watch, setError, formState: { errors }, handleSubmit } = useForm({
        resolver: yupResolver(schema)
    });

    const watchEmail = watch("login");
    const watchPass = watch("password");

    const onSubmit = async (data) => {
        setIsSubmitting(true);
        const formData = new FormData();
        formData.append("login", data.login);
        formData.append("password", data.password);

        try {
            const response = await axiosInstance.post('authenticateByEmail', formData);
            if(response.data.result){
                localStorage.setItem('auth_key', response.data.data.auth_key);
                window.location.href = process.env.PUBLIC_URL + '/cabinet';
            }else{
                if(response.data.error) {
                    Object.keys(response.data.error).map((err, i) => {
                        setError(err, { type: 'custom', message: response.data.error[err][0] });
                    });
                }
            }
            setIsSubmitting(false);
        } catch (error) {
            if(error.response.data){
                error.response.data.map((err, i) => {
                    setError(err.field, { type: 'custom', message: err.message });
                })
            }
            setIsSubmitting(false);
        }
    };

    useEffectOnce(()=>{
        setValue('remember', true);
    });

    return (
        <>
            <div className="modal__ct">
                <div className="modal__ct-box">
                    <div className="modal__header d-flex justify-content-center">
                        <div className="sc__title-wrap">
                            <div className="modal__title gradient-text">
                                Вход
                            </div>
                            <span className="star__light-decor star__light-decor--1"/>
                            <span className="star__light-decor star__light-decor--2"/>
                        </div>
                    </div>
                    <div className="modal__subtitle text-center">
                        Войти с помощью учетной записи
                    </div>
                    <div className="social__links d-flex justify-content-center">
                        <a href={process.env.REACT_APP_BE_SOC + 'auth-google'} className="soc__btn d-flex align-items-center justify-content-center">
                            <svg width="60" height="61" viewBox="0 0 60 61" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M41.7943 28.3135H30.2511V32.9535H36.8395C36.5536 34.4469 35.696 35.7269 34.3892 36.5802L38.3504 39.5936C40.6645 37.5002 41.9985 34.4335 41.9985 30.7802C42.0121 29.9269 41.9305 29.1002 41.7943 28.3135Z" fill="#8E1348"/>
                                <path d="M34.4027 36.58C33.3001 37.3 31.898 37.7267 30.2509 37.7267C27.0656 37.7267 24.3568 35.62 23.3903 32.78L19.293 35.8867C21.3212 39.8067 25.4594 42.5 30.2509 42.5C33.5587 42.5 36.3356 41.42 38.3639 39.5933L34.4027 36.58Z" fill="#8E1348"/>
                                <path d="M23.3905 28.2203L19.3068 25.1136C18.4764 26.7269 18 28.5669 18 30.5003C18 32.4336 18.4764 34.2736 19.3068 35.8869L23.4041 32.7803C23.1455 32.0603 23.0093 31.2869 23.0093 30.5003C23.0093 29.7136 23.1455 28.9403 23.3905 28.2203Z" fill="#8E1348"/>
                                <path d="M30.2509 18.5C25.4594 18.5 21.3214 21.1936 19.3068 25.1136L23.4039 28.22C24.3568 25.38 27.0656 23.2733 30.2509 23.2733C32.0477 23.2733 33.6676 23.8733 34.9335 25.0733L38.4455 21.6333C36.322 19.6867 33.5587 18.5 30.2509 18.5Z" fill="#8E1348"/>
                            </svg>
                        </a>
                        <a href={process.env.REACT_APP_BE_SOC + 'auth-mail'} className="soc__btn d-flex align-items-center justify-content-center">
                            <svg width="60" height="61" viewBox="0 0 60 61" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M34.5118 30.5C34.5118 32.9879 32.4879 35.0118 30 35.0118C27.5121 35.0118 25.4882 32.9879 25.4882 30.5C25.4882 28.0121 27.5121 25.9882 30 25.9882C32.4879 25.9882 34.5118 28.0121 34.5118 30.5ZM30 15.5C21.7286 15.5 15 22.2286 15 30.5C15 38.7714 21.7286 45.5 30 45.5C33.03 45.5 35.9518 44.5979 38.4493 42.8911L38.4921 42.8611L36.4714 40.5125L36.4371 40.5339C34.515 41.7714 32.2886 42.425 30 42.425C23.4246 42.425 18.075 37.0754 18.075 30.5C18.075 23.9246 23.4246 18.575 30 18.575C36.5754 18.575 41.925 23.9246 41.925 30.5C41.925 31.3518 41.8296 32.2143 41.6443 33.0629C41.2671 34.6111 40.1829 35.0846 39.3696 35.0225C38.5511 34.9561 37.5932 34.3732 37.5868 32.9461V31.8586V30.5C37.5868 26.3161 34.1839 22.9132 30 22.9132C25.8161 22.9132 22.4132 26.3161 22.4132 30.5C22.4132 34.6839 25.8161 38.0868 30 38.0868C32.0325 38.0868 33.9386 37.2929 35.3754 35.8475C36.2111 37.1482 37.5729 37.9636 39.1232 38.0879C39.2561 38.0986 39.3921 38.1039 39.5261 38.1039C40.6179 38.1039 41.6989 37.7386 42.5711 37.0775C43.47 36.3939 44.1418 35.4071 44.5125 34.2211C44.5714 34.0293 44.6807 33.5911 44.6807 33.5879L44.6839 33.5718C44.9025 32.6204 45 31.6721 45 30.5C45 22.2286 38.2714 15.5 30 15.5Z" fill="#8E1348"/>
                            </svg>
                        </a>
                        <a href={process.env.REACT_APP_BE_SOC + 'eauth?service=yandex'} className="soc__btn d-flex align-items-center justify-content-center">
                            <svg width="60" height="61" viewBox="0 0 60 61" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M37.6279 42.5H33.5207V21.744H31.6867C28.3287 21.744 26.5721 23.4582 26.5721 26.0165C26.5721 28.9176 27.7862 30.2626 30.2918 31.9769L32.3583 33.4011L26.4171 42.5H22L27.3471 34.3769C24.2732 32.1352 22.5425 29.9462 22.5425 26.2538C22.5425 21.6385 25.6939 18.5 31.6609 18.5H37.6021V42.5H37.6279Z" fill="#8E1348"/>
                            </svg>
                        </a>
                    </div>
                </div>
                <div className="modal__ct-box">
                    <div className="modal__subtitle modal__subtitle--indent text-center">
                        или войти с помощью <br/>
                        электронной почты
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)} className={'modal__form' + (isSubmitting ? ' form--loading' : '')}>
                        <div className="form__list">
                            <div className="row">
                                <div className="col-12 form__it">
                                    <div className={'form__field' + (errors.login ? ' has-error' : '') + ((watchEmail && watchEmail != '') ? ' has-value' : '')}>
                                        <input
                                            type="text"
                                            id="login"
                                            autoComplete="nope"
                                            {...register("login")}
                                        />
                                        <label htmlFor="login" className="form__label">
                                            Электронная почта
                                        </label>
                                    </div>
                                    {errors.login && (
                                        <p className="error__message">
                                            {errors.login?.message}
                                        </p>
                                    )}
                                </div>
                                <div className="col-12 form__it">
                                    <div className={'form__field' + (errors.password ? ' has-error' : '') + ((watchPass && watchPass != '') ? ' has-value' : '')}>
                                        <input
                                            type="password"
                                            id="password"
                                            autoComplete="current-password"
                                            {...register("password")}
                                        />
                                        <label htmlFor="password" className="form__label">
                                            Пароль
                                        </label>
                                    </div>
                                    {errors.password && (
                                        <p className="error__message">
                                            {errors.password?.message}
                                        </p>
                                    )}
                                </div>
                                <div className="col-12 form__it form__it--action d-flex align-items-center justify-content-between">
                                    <div className="checkbox__wrap">
                                        <label className="align-items-center">
                                            <input
                                                type="checkbox"
                                                {...register("remember")}
                                            />
                                            <div className="checkbox__decor"/>
                                            <div className="checkbox__title">
                                                Запомнить меня
                                            </div>
                                        </label>
                                    </div>
                                    <div className="modal__form-text">
                                        <a onClick={()=>{
                                            show(<Pass/>, "modal--form modal--pass");
                                        }}>Забыли пароль?</a>
                                    </div>
                                </div>
                                <div className="col-12 form__it form__it--action d-flex justify-content-center">
                                    <button type="submit" className="btn-ct btn--full d-flex align-items-center justify-content-center">
                                        <span>Войти</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                {!end.end && (
                    <div className="modal__ct-box">
                        <div className="modal__subtitle modal__subtitle--bottom text-center">
                            <span className="gradient-text">Нет профиля?</span> <a onClick={()=>{
                            show(<Reg/>, "modal--form modal--reg");
                        }} >Зарегистрируйтесь</a>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}

export default Login;
