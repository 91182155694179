import React, {useEffect, useState} from "react";
import "keen-slider/keen-slider.min.css"
import * as yup from "yup";
import {useForm} from "react-hook-form";
import axiosInstance from "../helpers/axios";
import {yupResolver} from "@hookform/resolvers/yup";
import useEffectOnce from "../hooks/useEffectOnce";
import {pagination} from "../helpers/pagination";

const perPage = 10;

function Winners() {
    const [searchLoading, setSearchLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [winnersEmpty, setWinnersEmpty] = useState('Победители не определены');
    const [winnersType, setWinnersType] = useState(null);
    const [winners, setWinners] = useState([]);
    const [winPaging, setWinPaging] = useState({
        total: 1,
        current_page: 1
    });

    const schema = yup.object({
        email: yup.string().email('Некорректный email').required('Некорректный email'),
    }).required();

    const { register, watch, setError, setValue, clearErrors, reset, formState: { errors }, handleSubmit } = useForm({
        resolver: yupResolver(schema)
    });

    const watchEmail = watch("email");

    const onSearchSubmit = async (data) => {
        setLoading(true);
        setSearchLoading(true);
        setWinnersType(null);
        getWinners(1, true);
    }

    useEffectOnce(()=>{
        setWinnersType('day');
    });

    useEffect(()=>{
        if(winnersType){
            getWinners(1);
            setValue('email', '');
        }
    }, [winnersType]);

    const getWinners = async(page = 1, search = null) => {
        setLoading(true);

        try {
            const response = await axiosInstance.get('getWinners?page=' + page + (search ? '&email=' + watchEmail : '&type=' + winnersType));
            if(response.data.result){
                console.log(response.data);
                setWinners(response.data.data.rows);
                setWinPaging(response.data.data.paging);
                setLoading(false);
                setSearchLoading(false);

                if(search){
                    setWinnersEmpty('Среди победителей данный аккаунт не найден');
                }else{
                    setWinnersEmpty('Победители не определены');
                }
            }
        } catch (error) {
            if(error.response && error.response.data){

            }

            setLoading(false);
            setSearchLoading(false);
        }
    }

    const renderPager = () => {
        let paging_arr = pagination(parseInt(winPaging.current_page), winPaging.total);
        if(winPaging.total > 1){
            return(
                <div className="pager d-flex align-items-center justify-content-center">
                    <div onClick={()=>{
                        if(winPaging.current_page !== 1){
                            getWinners(parseInt(winPaging.current_page) - 1);
                        }
                    }} className={'box__sl-nav box__sl-nav--prev d-flex align-items-center justify-content-center ' + ((winPaging.current_page === 1) ? 'disabled' : '')}>
                        <svg width="49" height="50" viewBox="0 0 49 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="49" height="49" rx="24.5" transform="matrix(1 0 0 -1 0 49.5)" fill="#720E39"/>
                            <path d="M28 16.4999L20 24.9999L28 33.4999" stroke="white" strokeWidth="2"/>
                        </svg>
                    </div>
                    <div className="page__list d-flex align-items-center">
                        {
                            paging_arr.map((page, index) => (
                                <div onClick={()=>{
                                    if(winPaging.current_page !== parseInt(page)){
                                        getWinners(parseInt(page))
                                    }
                                }} className={'pager__item ' + ((page == winPaging.current_page) ? 'active' : '') + ((page === '...') ? 'pager__item-dots' : '')}>
                                    <span>{page}</span>
                                </div>
                            ))
                        }
                    </div>
                    <div onClick={()=>{
                        if(winPaging.total !== winPaging.current_page){
                            getWinners(parseInt(winPaging.current_page) + 1);
                        }
                    }} className={'box__sl-nav box__sl-nav--next d-flex align-items-center justify-content-center ' + ((winPaging.total === winPaging.current_page) ? 'disabled' : '')}>
                        <svg width="49" height="50" viewBox="0 0 49 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="49" y="49.5" width="49" height="49" rx="24.5" transform="rotate(180 49 49.5)" fill="#720E39"/>
                            <path d="M21 16.4999L29 24.9999L21 33.4999" stroke="white" strokeWidth="2"/>
                        </svg>
                    </div>
                </div>
            )
        }
    }

    return (
        <div className="content">
            <div className="sc__winners">
                <div className="candy__decor candy__decor-5"/>
                <div className="container">
                    <div className="sc__header d-flex justify-content-center">
                        <div className="sc__title-wrap">
                            <div className="sc__title gradient-text">
                                <span>Победители</span>
                            </div>
                            <span className="star__light-decor"/>
                            <span className="star__light-decor star__light-decor--2"/>
                        </div>
                    </div>
                    <div className="lk__wrap">
                        <div className="win__search-wrap d-flex align-items-center justify-content-between">
                            <div className="win__types-list d-flex">
                                <div onClick={()=>{
                                    setWinnersType('day');
                                }} className={'win__types-it' + (winnersType === 'day' ? ' active' : '')}>
                                    Ежедневные призы
                                </div>
                                <div onClick={()=>{
                                    setWinnersType('main');
                                }} className={'win__types-it' + (winnersType === 'main' ? ' active' : '')}>
                                    Главные призы
                                </div>
                            </div>
                            <div className="win__search-bl">
                                <form className={'win__search' + (searchLoading ? ' form--loading' : '')} autoComplete="off" onSubmit={handleSubmit(onSearchSubmit)}>
                                    <div className={'form__field' + ((watchEmail && watchEmail != '') ? ' has-value' : '') + (errors.email ? ' has-error' : '')}>
                                        <input
                                            type="text"
                                            id="email-search"
                                            {...register("email")}
                                        />
                                        <label htmlFor="email-search" className="form__label">
                                            Найти себя по почте
                                        </label>
                                    </div>
                                    <button type="submit" className="search__submit">
                                        <svg width="58" height="58" viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="26.5" cy="26.5" r="10.5" stroke="#8E1348" strokeWidth="2"/>
                                            <path d="M34 33.5L42.5 42" stroke="#8E1348" strokeWidth="2"/>
                                        </svg>

                                    </button>
                                </form>
                                {errors.email && (
                                    <p className="error__message">
                                        {errors.email?.message}
                                    </p>
                                )}
                            </div>
                        </div>
                        {winners.length > 0 && (
                            <div className={'tb tb--winners' + (loading ? ' tb--loading' : '')}>
                                <div className="tb__head">
                                    <div className="tb__row">
                                        <div className="tb__col">
                                            <div className="tb__th">
                                                Эл.почта
                                            </div>
                                        </div>
                                        <div className="tb__col">
                                            <div className="tb__th">
                                                Дата
                                            </div>
                                        </div>

                                        <div className="tb__col">
                                            <div className="tb__th">
                                                Приз
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tb__body">
                                    {winners.length > 0 && winners.map((win, i) => (
                                        <div className="tb__row">

                                            <div className="tb__col">
                                                <div className="tb__td">
                                                    <span className="tb-email">{win.email}</span>
                                                </div>
                                            </div>
                                            <div className="tb__col">
                                                <div className="tb__td gradient-text2">
                                                    {win.date}
                                                </div>
                                            </div>
                                            <div className="tb__col">
                                                <div className="tb__td">
                                                    {win.prize}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                        {winners.length === 0 && (
                            <div className="tb__empty">
                                {winnersEmpty}
                            </div>
                        )}
                        {winPaging.total > 1 && (
                            renderPager()
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Winners;
