import React, {useState, useEffect} from 'react'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import {useModal, useProfile} from "../../context";
import Info from "./Info";
import axiosInstance from "../../helpers/axios";

const topics = [
    {
        title: 'Регистрация в акции',
        id: 1
    },
    {
        title: 'Получение призов',
        id: 2
    },
    {
        title: 'Технические вопросы',
        id: 3
    },
    {
        title: 'Вопрос о продукте',
        id: 6
    },
    {
        title: 'Другое',
        id: 4
    }
];

function Feedback({product = false}){
    const {profile} =  useProfile();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [theme, setTheme] = useState('');
    const [themeTitle, setThemeTitle] = useState('');
    const [attachFile, setAttachFile] = useState('');
    const [isThemeDropdownOpen, setIsThemeDropdownOpen] = useState(false);

    const { show, hide} = useModal();

    const schema = yup.object({
        name: yup.string().required('Некорректное Имя'),
        email: yup.string().email('Некорректный E-mail').required('Некорректный E-mail'),
        text: yup.string().min(6, 'Мин. 5 символов').required('Обязательно к заполнению'),
        theme_id: yup.string().required('Выберите тему обращения'),
        agree: yup.boolean().oneOf([true], 'Необходимо согласие'),
        agree2: yup.boolean().oneOf([true], 'Необходимо согласие')
    }).required();

    const { register,reset, setValue, clearErrors, watch, setFocus, setError, formState: { errors }, handleSubmit } = useForm({
        resolver: yupResolver(schema)
    });

    useEffect(()=>{
        //setValue('agree', true);
        if(product){
            setValue('theme_id', 6);
            setThemeTitle('Вопрос о продукте');
            setTheme(6);
        }
    }, []);

    const watchEmail = watch("email");
    const watchName = watch("name");
    const watchMess = watch("text");
    const watchTheme = watch("theme_id");

    useEffect(() => {
        if(!profile.isLoading && Object.keys(profile.data).length > 0){
            setValue('email', profile.data.profile.email);
        }
    },[profile]);

    const onSubmit = async (data) => {
        setIsSubmitting(true);
        const formData = new FormData();
        const fileInput = document.querySelector('#file') ;
        formData.append("name", data.name);
        formData.append("email", data.email);
        formData.append("text", data.text);
        formData.append("subject_id", data.theme_id);
        formData.append("accept1", 1);

        if(fileInput.files[0] !== undefined){
            formData.append('file', fileInput.files[0]);
        }

        try {
            const response = await axiosInstance.post('sendFeedback', formData);
            if(response.data.result){
                reset();
                setAttachFile('');
                setThemeTitle('');
                setTheme('');
                show(<Info
                    icon={true}
                    title="спасибо <br/> <span>за обращение</span>"
                    text="ваш вопрос будет обработан в&nbsp;течение 24&nbsp;часов. Ответ будет отправлен на&nbsp;указанный электронный адрес"
                    close={hide}/>, "modal--info");

                if(localStorage.getItem('auth_key')){
                    setValue('name', profile.data.name);
                    setValue('email', profile.data.email);
                }
            }else{
                if(response.data.error) {
                    Object.keys(response.data.error).map((err, i) => {
                        setError(err, { type: 'custom', message: response.data.error[err][0] });
                    });
                }
            }
            setIsSubmitting(false);
        } catch (error) {
            if(error.response.data){
                error.response.data.map((err, i) => {
                    setError(err.field, { type: 'custom', message: err.message });
                })
            }
            setIsSubmitting(false);
        }
    };

    return (
        <>
            <div className="feedback__bg-decor desk-only"/>
            <div className="modal__ct">
                <div className="modal__ct-box">
                    <div className="modal__header d-flex justify-content-center">
                        <div className="sc__title-wrap">
                            <div className="modal__title gradient-text">
                                задать <br className="mb-only"/><span>вопрос</span>
                            </div>
                            <span className="star__light-decor star__light-decor--1"/>
                            <span className="star__light-decor star__light-decor--2"/>
                        </div>
                    </div>
                </div>
                <div className="modal__ct-box">
                    <form onSubmit={handleSubmit(onSubmit)} className={'modal__form' + (isSubmitting ? ' form--loading' : '')}>
                        <div className="form__list">
                            <div className="row">
                                <div className="col-6 form__it">
                                    <div
                                        className={'form__field' + (errors.email ? ' has-error' : '') + ((watchEmail && watchEmail != '') ? ' has-value' : '')}>
                                        <input
                                            autoComplete="username"
                                            type="text"
                                            id="email"
                                            {...register("email")}
                                        />
                                        <label htmlFor="email" className="form__label">
                                            Электронная почта
                                        </label>
                                    </div>
                                    {errors.email && (
                                        <p className="error__message">
                                            {errors.email?.message}
                                        </p>
                                    )}
                                </div>
                                <div className="col-6 form__it">
                                    <div className={'form__field' + (errors.name ? ' has-error' : '') + ((watchName && watchName != '') ? ' has-value' : '')}>
                                        <input
                                            type="text"
                                            id="name"
                                            {...register("name")}
                                        />
                                        <label for="name" className="form__label">
                                            Имя
                                        </label>
                                    </div>
                                    {errors.name && (
                                        <p className="error__message">
                                            {errors.name?.message}
                                        </p>
                                    )}
                                </div>

                                <div className="col-12 form__it">
                                    <div className={'form__select' + (isThemeDropdownOpen ? ' active' : '') + (theme != '' ? ' has-value' : '') + (errors.theme_id ? ' has-error' : '')}>
                                        <div onClick={()=>{
                                            setIsThemeDropdownOpen(!isThemeDropdownOpen);
                                        }} className="form__select-input d-flex align-items-center justify-content-between">
                                            <div className="form__label">
                                                Тема обращения
                                            </div>
                                            <div className={'form__select-value' + (theme == '' ? ' form__select-value--placeholder' : '')}>
                                                {theme != '' ? themeTitle : ''}
                                            </div>
                                            <div className="form__select-toggle">
                                                <svg width="27" height="16" viewBox="0 0 27 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M-6.32254e-07 1.00056C3.05357 1.00056 10.0286 3.89342 13.5 15.4648" stroke="#9F661F" strokeWidth="2"/>
                                                    <path d="M-6.32254e-07 1.00056C3.05357 1.00056 10.0286 3.89342 13.5 15.4648" stroke="#9F661F" strokeWidth="2"/>
                                                    <path d="M27 1.00056C23.9464 1.00056 16.9714 3.89342 13.5 15.4648" stroke="#9F661F" strokeWidth="2"/>
                                                    <path d="M27 1.00056C23.9464 1.00056 16.9714 3.89342 13.5 15.4648" stroke="#9F661F" strokeWidth="2"/>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="form__select-dropdown">
                                            <div className="form__dropdown-list">
                                                {topics.map((it, i) => (
                                                    <div onClick={()=>{
                                                        setIsThemeDropdownOpen(false);
                                                        setThemeTitle(it.title);
                                                        setTheme(it.id);
                                                        setValue('theme_id', it.id);
                                                        clearErrors('theme_id');
                                                    }} className={'form__dropdown-it' + (it.id == theme ? ' active' : '')}>
                                                        {it.title}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>

                                    {errors.theme_id && (
                                        <p className="error__message">
                                            {errors.theme_id?.message}
                                        </p>
                                    )}
                                </div>
                                <div className="col-8 form__it">
                                    <div className={'form__field' + (errors.text ? ' has-error' : '') + ((watchMess && watchMess != '') ? ' has-value' : '')}>
                                        <textarea
                                            id="text"
                                            {...register("text")}
                                        />
                                        <label for="text" className="form__label">
                                            Ваш вопрос
                                        </label>
                                    </div>
                                    {errors.text && (
                                        <p className="error__message">
                                            {errors.text?.message}
                                        </p>
                                    )}
                                </div>
                                <div className="col-4 form__it form__it--attach d-flex align-items-center">
                                    <label className="d-flex align-items-center form__attach-btn">
                                        <input type="file" id="file" {...register("attachment")} accept="image/*" onChange={(event)=>{
                                            const fileInput = document.querySelector('#file') ;
                                            const files = event.target.files;
                                            const fileName = files[0].name;
                                            setAttachFile(fileName);
                                        }}/>
                                        <div className={'file__attach-btn-icon d-flex align-items-center justify-content-center' + (attachFile != '' ? ' has-image' : '')}>
                                            <svg width="17" height="34" viewBox="0 0 17 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M15.8059 7.56311C15.18 7.56311 14.6726 8.07048 14.6726 8.69634L14.6726 24.9393C14.6726 28.2786 11.968 30.9832 8.62872 30.9832C5.28947 30.9832 2.58482 28.2786 2.58482 24.9393L2.58482 6.05214C2.58482 5.0503 2.9828 4.08949 3.69121 3.38109C4.39961 2.67268 5.36042 2.2747 6.36226 2.2747C7.3641 2.2747 8.3249 2.67268 9.03331 3.38109C9.74172 4.08949 10.1397 5.0503 10.1397 6.05214L10.1397 21.9174C10.1397 22.7484 9.45976 23.4283 8.62872 23.4283C7.79768 23.4283 7.11775 22.7484 7.11775 21.9174V8.69635C7.11775 8.07048 6.61038 7.56311 5.98451 7.56311C5.35865 7.56311 4.85128 8.07048 4.85128 8.69634V21.9174C4.85128 22.9192 5.24926 23.88 5.95767 24.5884C6.66608 25.2968 7.62688 25.6948 8.62872 25.6948C9.63056 25.6948 10.5914 25.2968 11.2998 24.5884C12.0082 23.88 12.4062 22.9192 12.4062 21.9174L12.4062 6.05214C12.4062 2.71288 9.70151 0.00823975 6.36226 0.00823975C3.023 0.00823975 0.318359 2.71288 0.318359 6.05214L0.318359 24.9393C0.318359 29.5327 4.03536 33.2497 8.62872 33.2497C13.2221 33.2497 16.9391 29.5327 16.9391 24.9393V8.69634C16.9391 8.07048 16.4317 7.56311 15.8059 7.56311Z" fill="#9F661F"/>
                                            </svg>
                                        </div>
                                        <span>{attachFile ? attachFile : 'Загрузить изображение'}</span>

                                        {attachFile != '' && (
                                            <div onClick={(e)=>{
                                                setValue('attachment', null);
                                                setAttachFile('');
                                                e.preventDefault();
                                                e.stopPropagation();
                                            }} className="file__clear">
                                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 2.69235L2.69231 2.00005L19.9999 19.3077L19.3076 20L2 2.69235Z" fill="#9D0250"></path><path d="M19.3077 2L20 2.69231L2.69237 20L2.00006 19.3076L19.3077 2Z" fill="#9D0250"></path></svg>
                                            </div>
                                        )}
                                    </label>

                                </div>
                                <div className="col-12 form__it form__it--action">
                                    <div className="checkbox__it">
                                        <div className={'checkbox__wrap' + (errors.agree ? ' has-error' : '')}>
                                            <label className="align-items-start">
                                                <input
                                                    type="checkbox"
                                                    {...register("agree")}
                                                />
                                                <div className="checkbox__decor"/>
                                                <div className="checkbox__title text-left">
                                                    Я&nbsp;ознакомился с&nbsp;<a href="https://www.mars.com/privacy-policy-russian" target="_blank">Положением о&nbsp;конфиденциальности</a>, <a href="/user-agreement.pdf" target="_blank">Пользовательским соглашением</a> и&nbsp;даю свое согласие на&nbsp;обработку персональных данных. Персональные данные, отправленные через эту форму, будут использованы только для ответа на&nbsp;конкретный вопрос, а&nbsp;также в&nbsp;целях аналитики, и&nbsp;не&nbsp;будут использоваться в&nbsp;маркетинговых целях
                                                </div>
                                            </label>
                                        </div>
                                        {errors.agree && (
                                            <p className="error__message">
                                                {errors.agree?.message}
                                            </p>
                                        )}
                                    </div>
                                    <div className="checkbox__it">
                                        <div className={'checkbox__wrap' + (errors.agree2 ? ' has-error' : '')}>
                                            <label className="align-items-center">
                                                <input
                                                    type="checkbox"
                                                    {...register("agree2")}
                                                />
                                                <div className="checkbox__decor"/>
                                                <div className="checkbox__title text-left">
                                                    Мне исполнилось 18&nbsp;лет
                                                </div>
                                            </label>
                                        </div>
                                        {errors.agree2 && (
                                            <p className="error__message">
                                                {errors.agree2?.message}
                                            </p>
                                        )}
                                    </div>
                                </div>
                                <div className="col-12 form__it form__it--action">
                                    <button type="submit" className="btn-ct btn--full d-flex align-items-center justify-content-center">
                                        <span>Отправить</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}

export default Feedback;
